<template>
  <div class="table-sort-cell">
    <el-tooltip
      :disabled="sortText.length <= 6"
      :content="sortText"
      placement="top"
    >
      <span class="ellipsis table-sort-cell-sort-text" @click="saveSort">{{
        sortText
      }}</span>
    </el-tooltip>
  </div>
</template>

<script setup>
import { defineProps, computed, h, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { saveEaCompanySort } from "@/api/company";
import batchSortCom from "@/components/batchSortCom/index.vue";
const props = defineProps({
  row: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  // 跳转需要的参数，默认是 id
  idKey: {
    type: String,
    default: "id",
  },
});
const sortText = computed(() => {
  return props.row.sortCom || "默认";
});
const saveSort = () => {
  const content = ref();
  ElMessageBox({
    title: "提示",
    autofocus: false,
    cancelButtonText: "取消",
    showCancelButton: true,
    message: h(batchSortCom, {
      content: content.value,
      onUpdateVal: (val) => {
        content.value = val.trim();
      },
    }),
  })
    .then(() => {
      let param = {
        id: props.row[props.idKey],
        sortCom: content.value,
      };
      saveEaCompanySort(param).then((res) => {
        if (res.data.msg == "success") {
          props.row.sortCom = content.value;
          ElMessage.success("保存成功");
        }
      });
    })
    .catch(() => {});
};
</script>

<style scoped lang="scss">
.table-sort-cell-sort-text {
  color: orange;
  cursor: pointer;
  display: block;
  width: 100%;
}
</style>
