<template>
  <qzf-tabs v-model:value="value" @tab-click="handleClick">

    <qzf-tabs-item label="财务期初" name="3" :cache="false">
      <initTable ref="initTable" :activeName ="value"></initTable>
    </qzf-tabs-item>

    <qzf-tabs-item label="税务期初" name="1" :cache="false">
      <initTable ref="initTable" :activeName ="value"></initTable>
    </qzf-tabs-item>
    <qzf-tabs-item label="年度亏损" name="4" :cache="false">
      <initNdks ref="initNdks"></initNdks>
    </qzf-tabs-item>
    <qzf-tabs-item label="个税期初" name="2" :cache="false">
      <personalTax></personalTax>
    </qzf-tabs-item>
  </qzf-tabs>
  <!-- <div class="app-container">
    <initTable ref="initTable" :activeName = "activeName"></initTable>
  </div> -->

</template>

<script>
import initTable from "./components/initTable.vue"
import personalTax from "./components/personalTax.vue"
import initNdks from "./components/initNdks.vue"
export default {
  name:'kjInit',
  components:{
    initTable,
    personalTax,
    initNdks
  },
  data(){
    return {
      activeName:'first',
      value:'3',
    }
  },
  methods:{
    // handleClick(tab, event) {
    //   if (tab.props.label == "已采集") {
    //     this.listQuery.setUpStatusCj = [1]
    //     this.tabName = tab.props.name
    //     this.$refs.initTable.getList()
    //   } else if (tab.props.label == "跳过采集") {
    //     this.tabName = tab.props.name
    //     this.listQuery.setUpStatusCj = [2]
    //     this.$refs.initTable.getList()
    //   } 
    // },
  }
}
</script>

<style lang="scss" scoped>

</style>