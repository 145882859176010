<template>
  <div >
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="专项附加扣除-累计数" name="first"> 
      </el-tab-pane>
      <el-tab-pane label="扣缴端数据导入" name="second">
        <personTransfer ref="personTransfer" ></personTransfer>
      </el-tab-pane>
      <div class="topBtn" v-if="activeName == 'first'">
        <div>
          <el-button icon="RefreshRight" size="small" style="margin-right: 5px;" @click="getList()"></el-button>
          <el-input size="small" placeholder="请输入公司名称/编号" v-model="listQuery.name" style="width:150px" @keyup.enter="getList" clearable></el-input>
          <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
            <el-icon><Search /></el-icon> <span  >搜索</span>
          </el-button>
          <search @success="getList" @cancel="cancel">
            <el-form style="margin:21px 0px 0px 6px;height:250px;width:450px" class="styleForm" label-width="100px">
            <el-form-item label="采集状态：">
              <el-checkbox-group size="small" v-model="listQuery.taskStatus">
                <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="选择人员：">
              <selectuser code="bookkeeping" v-model:userId="listQuery.userId" style="width:140px;margin: 0;"></selectuser>
            </el-form-item>
            <el-form-item label="税局：" > 
              <selectcity v-model:citys="listQuery.districtCode" style="width:140px;margin: 0;"></selectcity>
            </el-form-item>
            <el-form-item label="采集账期：">
              <qzf-search-period v-model:period="listQuery.period" style="width:140px;margin: 0;"></qzf-search-period>
            </el-form-item>
            <el-form-item label="包含员工：">
              <el-radio v-model="listQuery.EmpStatus" :label="2">未添加员工</el-radio>
              <el-radio v-model="listQuery.EmpStatus" :label="1">已添加员工</el-radio>
              <el-radio v-model="listQuery.EmpStatus" :label="0">全部</el-radio>
            </el-form-item>
          </el-form>
          </search>
        </div>
        <div>
          <el-button style="margin-left:10px" type="primary" size="small" @click="handleljcshInit()" :disabled="!$buttonStatus('gscsh_ljcsh')" :loading="btnLoading">
            <el-icon><Files /></el-icon><span  > 采集累计数-专项附加扣除</span>
          </el-button>
        </div>
      </div>
      <div v-if="activeName == 'first'">
        <el-table stripe :height="contentStyleObj" @select="handleSelectionChange" v-loading="listLoading" :data="list" border highlight-current-row style="width: 100%; margin-top: 10px;" @sort-change="sortChange" ref="tableScroll" id="tableLazyLoadPersonal" @select-all="handleSelectionChangeAll">
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column type="selection" align="center" width="40"/>
          <el-table-column  label="编号" align="center" prop="sort" width="80" fixed="left" sortable="custom">
              <template #default="scope">
                <TableSortCell :row="scope.row" idKey="comId" />
              </template>
          </el-table-column>
          <el-table-column label="公司名称" align="left" min-width="240">
            <template #default="scope">
              <TagNameCopy :row="scope.row"  idKey="comId" :showAuthIcon="false" :showUnderLine="true" :status="scope.row?.status"></TagNameCopy>
            </template>
          </el-table-column>
          <el-table-column prop="districtName" label="税局" width="65" align="center">
            <template #default="scope">
              <span>{{ $cityFilter(scope.row?.district) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="初始账期" align="center" min-width="100">
            <template #default="{row}">
              <span>{{row?.startAccountPeriod}}</span>
            </template>
          </el-table-column>
          <el-table-column label="末次采集账期" align="center" min-width="120" >
            <template #default="{row}">
              <span>{{row?.mccjGs1Period}}</span>
            </template>
          </el-table-column>
          <el-table-column label="采集状态" min-width="100">
            <template #default="scope">
              <span v-if="scope.row?.image && scope.row?.taskStatus == 4" >   
                <i class="iconfont icon-picture" v-if="scope.row?.taskStatus == 4" style="color:red;font-size:16px;" @click="open(scope.row, scope.$index)"></i>
              </span>
              <span v-if="scope.row?.image && scope.row?.taskStatus !== 4">
                <i class="iconfont icon-duihao" v-if="scope.row?.taskStatus == 3" style="color:#67c23a;font-size:13px;" ></i>
                <i v-if="scope.row?.taskStatus == 0" class="iconfont icon-gantanhao"></i>
                <i v-if="scope.row?.taskStatus == 1" class="iconfont icon-info"></i>
                <i v-if="scope.row?.taskStatus == 2" class="iconfont icon-info"></i>
              </span>
              <div class="item_icon">
                <p>
                  <i v-if="scope.row?.taskStatus == 0 && !scope.row?.image" class="iconfont icon-gantanhao"></i>
                  <i v-if="scope.row?.taskStatus == 1 && !scope.row?.image" class="iconfont icon-info"></i>
                  <i v-if="scope.row?.taskStatus == 2 && !scope.row?.image" class="iconfont icon-info"></i>
                  <i v-if="scope.row?.taskStatus == 99 && !scope.row?.image" class="iconfont icon-info"></i>
                  <i v-if="scope.row?.taskStatus == 3 && !scope.row?.image" class="iconfont icon-duihao"></i>
                  <i v-if="scope.row?.taskStatus == 4 && !scope.row?.image" class="iconfont icon-cuowu"></i>
                  <el-tooltip :disabled="!scope.row.errLog" class="item" effect="dark" placement="top-start">
                    <template #content>
                        <div v-html="scope.row?.errLog?scope.row?.errLog:'' + scope.row?.bussinessLog?scope.row?.bussinessLog:''"></div>
                      </template>
                     <span>{{$setUpStatusCj(scope.row?.taskStatus, scope.row?.bussinessStatus)}}
                       <i class="iconfont icon-wenhao" v-if="scope.row.errLog && scope.row.taskStatus == 4" style="color:red"></i>
                    </span>
                  </el-tooltip>
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="采集时间" align="center" min-width="160">
            <template #default="{row}">
              <span>{{row?.beginAt}}</span>
            </template>
          </el-table-column>
          <el-table-column label="专项扣除初始化时间" align="center" min-width="170" v-if="activeName == 'second'">
            <template #default="{row}">
              <span>{{row?.beginAt}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" min-width="280">
            <template #default="scope">
              <el-button @click="checkDetail(scope.row)" size="small" link  :disabled="!scope.row?.mccjGs1Period || scope.row?.taskStatus != 3">查看累计数</el-button>
              <el-button @click="checkZxfjkcDetail(scope.row)" size="small" link  :disabled="!scope.row?.mccjGs1Period || scope.row?.taskStatus != 3">查看专项附加扣除</el-button>
              <el-button @click="handleTaxTableLjs(scope.row)" size="small" link :loading="scope.row.btnLoading" :disabled="scope.row.status!=2">采集</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <qzf-pagination v-show="userTotal>0" :total="userTotal" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" type="personalTax"/>
        </div>
      </div>
    </el-tabs>

    <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogVisibleCsh" title="重新采集" width="800px" >
      <div style="margin-left: 12px;">
        <div>
          <el-radio :label="1" v-model="cshStatus">默认初始账期</el-radio>
          <span v-if="cshStatus == 1" style="color:#17a2b8;font-size: 13px;"><i class="iconfont icon-gantanhao1"></i>按照初始建账账期采集</span>
        </div>
        <div>
          <el-radio :label="2"  v-model="cshStatus">自选账期</el-radio>&nbsp;&nbsp;&nbsp;&nbsp;
          <span v-if="cshStatus == 2">
            <qzf-search-period v-model:period="cshPeriod" style="width:150px;"></qzf-search-period>
            <span v-if="cshStatus == 2 && listQuery.taskName == 'gs-cj-ljsjcj'" style="margin-left: 3px;color:#17a2b8;font-size: 13px;"><i class="iconfont icon-gantanhao1"></i>如果采集1-4月，请选择5账期</span>
            <span v-if="cshStatus == 2 && listQuery.taskName == 'gs-cj-zxfjkccj'" style="margin-left: 3px;color:#17a2b8;font-size: 13px;"><i class="iconfont icon-gantanhao1"></i>如果采集4账期，请选择4账期</span>
          </span>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogVisibleCsh = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleljcsh">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>
</template>

<script>
import { companyInitTaxListNoPeriod } from '@/api/company.js'
import { sendTask } from "@/api/collection"
import sbImgUrl from '../../batch/components/sbImgUrl.vue'
import { lastAccountPerio } from '@/utils'
import personTransfer from './personTransfer.vue'
import selectcity from "@/components/Screening/selectcity";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import { currentAccountPeriod } from "@/utils";
export default {
  name:'personalTax',
  components:{
    sbImgUrl,
    personTransfer,
    selectcity,
    TagNameCopy,
    TableSortCell
  },
  data() {
    return {
      activeName: "first",
      listQuery:{
        page:1,
        limit:20,
        taskName: 'gs-cj-zxlj',
        name:'',
        initType:"1",
      },
      userTotal:0,
      list: [],
      gsMainIds: undefined,
      pageStatus: true,
      listLoading:false,
      options:[
        {
          value: 99,
          label: '发起中',
        },
        {
          value: 0,
          label: '未采集',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '采集中',
        },
        {
          value: 3,
          label: '采集完成',
        },
        {
          value: 4,
          label: '采集失败',
        }
      ],
      dialogVisibleCsh: false,
      cshStatus: 2,
      cshPeriod: "",
      selects:undefined,
      btnLoading:false,
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false,
      ids:[]
    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created() {
    this.listQuery.limit = localStorage.getItem('personalTax') ? localStorage.getItem('personalTax')*1 : 20
    this.contentStyleObj= this.$getHeight(245)
    this.getList()
    this.initBus()
  },
  methods: {
    initBus(){
      this.$bus.on("refreshTask", () => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds && this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if(res.taskStatus == 1) {
            status = true
          }
        })
        if(status == true) {
          companyInitTaxListNoPeriod(this.listQuery).then(res => {
            this.tableData = res.data.data.list ? res.data.data.list : []
            this.userTotal = res.data.data.total
            this.setScroll()
          })
        }
      });
    },
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        page:1,
        limit:originLimit,
        taskName: 'gs-cj-zxlj',
        name:'',
        initType:"1",
      }
      this.getList()
    },
    getList() {
      this.listLoading = true
      companyInitTaxListNoPeriod(this.listQuery).then(res => {
        this.listLoading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.userTotal = res.data.data.total
        this.setScroll()
      })
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.selects = []
      this.gsMainIds = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoadPersonal");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.selects.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    handleClick(tab) {
      if(tab.props.name =='first'){
        this.getList()
      }else if(tab.props.name == 'second'){
        this.$refs.personTransfer.getList()
      }
    },
    checkDetail(row){
      let param
      param = lastAccountPerio(row.mccjGs1Period)
      this.$store.dispatch('commons/setParam', {informationPeriod: param,informationComId: row.comId})
      this.$router.push({
        path: "/initEa/initChushihua",
        name: "initChushihua"
      });
    },
    checkZxfjkcDetail(row){
      this.$store.dispatch('commons/setParam', {deductionId: row.comId})
      this.$router.push({
        path: "/initEa/additionalDeduction",
        name: "additionalDeduction"
      });
    },
    getIds(){
      this.gsMainIds = []
      this.selects.map(info=>{
        this.gsMainIds.push(info.comId)
      })
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.selects = []
        this.gsMainIds = []
        this.allChecked = false
      }
    },
    //采集
    handleTaxTableLjs(row){
      this.cshStatus = 1
      this.ids = [row.comId]
      this.dialogVisibleCsh = true
    },
    //批量采集
    handleljcshInit(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      let fail = this.selects.find((v) => v.status != 2);
      if (fail) {
        this.$qzfMessage(`${fail.name}未转移到记账客户，请重新选择！`,1);
        return;
      }
      this.cshStatus = 2
      let test = currentAccountPeriod().slice(-2)
      this.cshPeriod = test == '01' ? "" : currentAccountPeriod()
      this.ids = this.gsMainIds
      this.dialogVisibleCsh = true
    },
    //累计初始化
    handleljcsh() {
      this.btnLoading = true
      let param = {
        taskName: this.listQuery.taskName,
        comIds: this.ids,
      }
      if(this.cshStatus == 2 && this.cshPeriod != ""){
        param.period = this.cshPeriod
      }
      this.dialogVisibleCsh = false
      sendTask(param).then(res => {
        this.btnLoading = false
        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    open(row, index) {
      let imageAll = []
      this.list.map(res => {
        let url = {
          name: res.name,
          url: ""
        }
        url.url = res.image ? 'https://file.listensoft.net' + res.image + "?" + Math.random(10): ""
        imageAll.push(url)
      })
      this.$refs.sbImgUrl.dialog(imageAll, index)
    },
    // 表格排序
    sortChange(data) {
      this.listQuery.desc = data.order == "descending" ? 1 : 0;
      this.getList()
    },
  }
}
</script>

<style lang="scss" scoped>
.topBtn {
  display: flex;
  margin-bottom:10px;
  justify-content: space-between;
}
i{
  display: inline-block;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon{
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
}
 
</style>