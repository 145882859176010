<template>
  <div class="top_select">
    <div>
      <el-button
        icon="RefreshRight"
        size="small"
        style="margin-right: 5px;"
        @click="getList()"
      ></el-button>
      <el-input
        size="small"
        style="width:200px"
        v-model="listQuery.name"
        @keyup.enter="getList"
        placeholder="请输入企业名称/编号"
        clearable
      ></el-input>
      <el-button
        size="small"
        type="primary"
        @click="getList"
        style="margin-right:5px"
      >
        <el-icon><Search /></el-icon><span> 搜索</span>
      </el-button>
      <search @success="getList" @cancel="cancel">
        <el-form
          style="margin:0px 0px 0px 6px;width:450px"
          class="styleForm"
          label-width="140px"
        >
          <el-form-item label="纳税人类型 ：">
            <selecttaxtype
              v-model:type="listQuery.type"
              style="width:150px;margin-left: 0;"
            ></selecttaxtype>
          </el-form-item>
          <el-form-item label="税局：" > 
            <selectcity v-model:citys="listQuery.districtCode" style="width:150px;margin: 0;"></selectcity>
          </el-form-item>
          <el-form-item label="获取数据状态 ：">
            <el-radio-group v-model="listQuery.getDataStatus">
              <el-radio label="">全部</el-radio>
              <el-radio label="已获取">已获取</el-radio>
              <el-radio label="未获取">未获取</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="数据迁移状态：">
            <el-checkbox-group size="small" v-model="listQuery.taskStatus">
              <el-checkbox-button
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.value"
                >{{ item.label }}</el-checkbox-button
              >
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </search>
    </div>
    <div>
      <el-button
        size="small"
        type="primary"
        @click="batchMirgate"
        :disabled="!$buttonStatus('gscsh_qy')"
        icon="Switch"
        >批量迁移</el-button
      >
    </div>
  </div>
  <el-table
    :data="list"
    :height="contentStyleObj"
    border
    @sort-change="sortChange"
    v-loading="loading"
    stripe
    @select="handleSelectionChange"
    ref="tableScroll"
    id="tableLazyLoad"
    @select-all="handleSelectionChangeAll"
  >
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column type="selection" align="center" width="55" />
    <el-table-column
      label="编号"
      align="center"
      width="80"
      fixed="left"
      sortable="custom"
    >
      <template #default="scope">
        <TableSortCell :row="scope.row" idKey="comId" />
      </template>
    </el-table-column>
    <el-table-column prop="name" label="公司名称" min-width="300">
      <template #default="scope">
        <TagNameCopy
          :row="scope.row"
          idKey="comId"
          :showAuthIcon="false"
        ></TagNameCopy>
      </template>
    </el-table-column>
    <el-table-column label="税局" width="65" align="center">
      <template #default="scope">
        <span>{{ $cityFilter(scope.row.district) }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="初始账期"
      min-width="120"
      prop="startAccountPeriod"
      align="center"
    >
    </el-table-column>
    <el-table-column prop="name" label="获取数据状态" min-width="130">
      <template #default="scope">
        <div class="item_icon" v-if="!scope.row.gsId">
          <i class="iconfont icon-gantanhao"></i>
          <p>未获取</p>
        </div>
        <div class="item_icon" v-else>
          <i class="iconfont icon-duihao"></i>
          <p>已获取</p>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="数据迁移状态" min-width="130">
      <template #default="scope">
        <div class="item_icon" v-if="scope.row.taskStatus == 0">
          <i class="iconfont icon-gantanhao"></i>
          <p>未迁移</p>
        </div>
        <div
          class="item_icon"
          v-if="scope.row.taskStatus == 1 || scope.row.taskStatus == 2"
        >
          <i class="iconfont icon-info"></i>
          <p>迁移中</p>
        </div>
        <div class="item_icon" v-if="scope.row.taskStatus == 3">
          <i class="iconfont icon-duihao"></i>
          <p>已迁移</p>
        </div>
        <el-tooltip
          class="item"
          effect="dark"
          placement="top-start"
          v-if="scope.row.taskStatus == 4"
        >
          <template #content>
            <div v-html="scope.row.errLog"></div>
          </template>
          <div class="item_icon">
            <i class="iconfont icon-cuowu"></i>
            <p>迁移失败</p>
            <i
              class="iconfont icon-wenhao"
              style="color:red;margin-left: 4px;"
            ></i>
          </div>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column label="操作" min-width="200" align="center">
      <template #default="scope">
        <el-button
          size="small"
          type="primary"
          plain
          :disabled="!scope.row.gsId || !$buttonStatus('gscsh_qy')"
          @click="migrateOne(scope.row)"
          icon="Switch"
          >迁移</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <div class="pagination">
    <qzf-pagination
      v-show="total > 0"
      :total="total"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.limit"
      @pagination="getList"
      type="personalTax"
    />
  </div>
   <!-- 迁移员工 -->
   <el-dialog :close-on-click-modal="false" title="迁移员工设置" v-model="dialogVisible" width="450px">
    <el-form :model="form">
      <el-form-item label="是否清空已存在的员工信息：">
        <el-radio-group v-model="form.empty">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取消</el-button>
        <el-button type="primary" @click="saveSet()" size="small" :loading="btnLoading">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { currentAccountPeriod } from "@/utils";
import { companyMoveGsList } from "@/api/company";
import { employeeMigrationTask } from "@/api/salary";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectcity from "@/components/Screening/selectcity";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
export default {
  name: "personTransfer",
  components: {
    selecttaxtype,
    selectcity,
    TagNameCopy,
    TableSortCell
  },
  props: {},
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        status: 2,
        name: "",
        period: currentAccountPeriod(),
      },
      contentStyleObj: {},
      total: 0,
      list: [],
      sels: [],
      statusOptions: [
        {
          value: 0,
          label: "未迁移",
        },

        {
          value: 3,
          label: "已迁移",
        },
        {
          value: 4,
          label: "迁移失败",
        },
      ],
      btnLoading:false,
      loading:false,
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false,
      dialogVisible:false,
      form:{}
    };
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem('personalTax') ? localStorage.getItem('personalTax')*1 : 20
    this.contentStyleObj = this.$getHeight(245);
  },
  methods: {
    cancel(){
      let originLimit = this.listQuery.limit
      this.listQuery= {
        page: 1,
        limit: originLimit,
        status: 2,
        name: "",
        period: currentAccountPeriod(),
      }
      this.getList()
    },
    getList() {
      this.loading = true
      companyMoveGsList(this.listQuery).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.tableData = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
          this.setScroll()
        }
      });
    },
    setScroll(){
      //先重置状态和数组
      this.allChecked = false
      this.sels = []
      //计算滚动页数
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.allChecked && that.sels.length == that.tableData.length){
              that.$refs.tableScroll.toggleAllSelection()
              that.allChecked = true
            }else if(that.sels.length != 0){
              that.sels.map(v=>{
                that.list.map(item=>{
                  if(item.comId == v.comId){
                    that.$nextTick(()=>{
                     that.$refs.tableScroll.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.comId == v.comId){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.sels = newArr
      }else{
        this.sels = val;
      }
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.sels = this.tableData
        this.allChecked = true
      }else{
        this.sels = []
        this.allChecked = false
      }
    },
    //迁移
    migrateOne(row) {
      this.form.period = this.listQuery.period
      this.form.comIds = [row.comId]
      this.form.empty = true
      this.dialogVisible = true
    },
    //批量迁移
    batchMirgate() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择公司", 1);
        return;
      }
      let fail = this.sels.find((v) => !v.gsId);
      if (fail) {
        this.$qzfMessage(`${fail.name}未获取数据，请重新选择！`,1);
        return;
      }
      let ids = [];
      this.sels.map((v) => {
        ids.push(v.comId);
      });
      this.form.period = this.listQuery.period
      this.form.comIds = ids
      this.form.empty = true
      this.dialogVisible = true
    },
    saveSet(){
      this.btnLoading = true
      employeeMigrationTask(this.form).then((res) => {
        this.btnLoading = false
        if (res.data.msg == "success") {
          this.$qzfMessage(res.data.data.info);
          this.getList();
          this.dialogVisible = false
        }
      });
    },
    // 表格排序
    sortChange(data) {
      this.listQuery.desc = data.order == "descending" ? 1 : 0;
      this.getList()
    },
  },
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon {
  display: inline-block;
  width: 81%;
  padding: 1px 3px;
  padding-right: 10px;
  i {
    display: inline-block;
    font-size: 13px;
    margin-right: 4px;
  }
  p {
    display: inline-block;
    font-size: 13px;
    color: #333;
  }
}
</style>
