<template>
  <div style="margin-bottom: 20px;">
    <p style="margin-bottom: 10px;">请输入编号</p>
    <el-input
      :modelValue="contentText"
      @input="updateVal"
      ref="inputRef"
    />
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      contentText:""
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.inputRef.focus();
    });
  },
  methods:{
    updateVal(val){
      this.contentText = val
      this.$emit('updateVal',val)
    }
  }
};
</script>
