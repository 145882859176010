<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogVisible"
    width="90%"
    :title="imgUrl[index].name">
    <div class="switchover">
      <div class="two_btn arrow_left" @click="topClick">
        <el-icon><ArrowLeftBold /></el-icon>
      </div>
      <div class="two_btn arrow_right" @click="bottomClick">
        <el-icon><ArrowRightBold /></el-icon>
      </div>
    </div>
    <div v-if="imgUrl[index].url" style="text-align: center;"><img :src="imgUrl[index].url" style="display:inline-block;max-width: 100%;" :style="{height:contentStyleObj}"></div>
    <span v-else><img style="margin-left: 24%;display:inline-block" :style="{height:contentStyleObj}" src="../../../assets/noAvailable.png" alt=""></span>
  </el-dialog>
</template>

<script>
export default {
  name: 'sbImgUrl',
  data() {
    return {
      imgUrl: [{
        url:"",
        name: ""
      }],   //所有图片地址
      name: '',     //公司名称
      dialogVisible: false, 
      index: 0, //当前图片的索引
      allIndex: 0, //图片总数
      contentStyleObj:{}, //高度变化

    }
  },
  created(){
    this.contentStyleObj=this.$getHeight(180)
  },
  methods: {
    dialog(imageAll, index) {
      this.allIndex = imageAll.length
      this.index = index
      this.imgUrl = imageAll
      this.dialogVisible = true
    },
    topClick() {
      if(this.index > 0) {
        this.index--
        return
      }
      this.$message({
        message: '没有上一张了',
        type: 'warning'
      });
      // if(this.allIndex >= 2) {
      //   this.$message({
      //     message: '当前是最后一条',
      //     type: 'warning'
      //   });
      // }
    },
    bottomClick() {
      if(this.index < this.allIndex - 1) {
        this.index++
        return
      }
      this.$message({
        message: '没有下一张了',
        type: 'warning'
      });
      // if(this.allIndex < 0) {
      //   this.$message({
      //     message: '当前是第一条',
      //     type: 'warning'
      //   });
      // }
    }
  }
}
</script>

<style lang="less" scoped>
.demo-image__preview {
  text-align: center;
}
.switchover {
  width: 20px;
  // height: 60px;
  text-align: center;
  .two_btn {
    font-size: 30px;
    color: #362721;
    line-height: 60px;
    background: #eee;
    cursor: pointer;
  }
  .arrow_left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .arrow_right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>