<template>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    v-model="dialogVisible"
    title="修改资产年初取数来源"
    width="400px"
  >
    <el-form>
      <el-form-item label="资产年初取数来源 ：">
        <el-select
          v-model="form.value"
          placeholder="请选择"
          size="small"
          style="width:200px"
        >
          <el-option label="采集税局报表" value="否"> </el-option>
          <el-option label="延用科目余额表年初" value="是"> </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="saveSetting">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { companySets } from "@/api/company";
export default {
  name: "editAsset",
  data() {
    return {
      dialogVisible: false,
      ids: [],
      form: {
        type:"code_zcfz_sj",
        value:""
      },
    };
  },

  methods: {
    init(items) {
      this.ids = items;
      this.dialogVisible = true;
      this.form = {
        type:"code_zcfz_sj",
        value:""
      }
    },
    saveSetting() {
      let param = {
        comIds: this.ids,
        set: this.form,
      };
      companySets(param).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("设置成功");
          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
